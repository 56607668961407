
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { getOVICoverageInfo } from "@/api/ovi_api";

import  SealProRenew from "@/views/modals/SealProRenew.vue"; 
import  SealProEncode from "@/views/modals/SealProEncode.vue"
export default defineComponent({
  name: "ovicoverageinfo",
  components: {
    SealProRenew,
    SealProEncode
  },
  data() {
    return {
      sealProMaxCoverage: "",
      activeCoverage: [],
      loading: true,
      allowChangeName: false,
      renewCoverage: {}, 
      renew_now_sealpro_key: 1,
      coverageSender: 0
    }
  },
  methods: {
    async getOviCoverageInfo(){
      this.loading = true;
      const response = await getOVICoverageInfo(); 
      this.sealProMaxCoverage = response.data.sealProMaxCoverage; 
      this.activeCoverage = response.data.activeCoverage;
      this.coverageSender = response.data.COVERAGE_SENDER;
      this.loading = false;
    },
    
    renewSealPro(coverage:any) { 
         
        coverage.coverage_transtype = "RENEW"; 
        this.renewCoverage = coverage;
        this.renew_now_sealpro_key += 1; //this will force reload the component
        var self = this;
        setTimeout(() => {   
          
          if(self.coverageSender == 2) {  
            this.renewCoverage = coverage;

            const btn2:any = this.$refs.renewNowSealproModalButton2;
            btn2.click();
          } else { 
            const btn:any = this.$refs.renewNowSealproModalButton;
            btn.click();
          } 
          
        }, 500);
      
    }
  },
  mounted(){
    this.getOviCoverageInfo();
  },
  setup() {
    const tabIndex = ref(0);

     
    onMounted(() => {
      // set the tab from previous
      tabIndex.value = parseInt(localStorage.getItem("ovicoverageinfotransferTab") || "0");

    });
     
  }
});
