
import { defineComponent, ref, onMounted } from "vue";

import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";

import { useForm } from "vee-validate";
import { getcoveragedata, renewcoverage } from "@/api/sealprorenew";
 

export default defineComponent({
  name: "new-address-modal",
    data() {
    return {   
          insured_firstName: "",
          insured_middleName: "",
          insured_lastName: "",
          insured_birthday: "",
          beneficiary_firstName:  "",
          beneficiary_middleName:  "",
          beneficiary_lastName:  "",
          beneficiary_birthday:  "",
          beneficiary_contact:  "",
          beneficiary_relationship:  "",
          coi: "",
          sealproPlan: "",
          issubmitting: false
    }
  },
  components: {
    ErrorMessage,
    Field,
    Form
  },
  props: { 
       
        sealpro_encode_modal: {type: String},
        renewCoverage:  {type: Object, default: {}},
  },  
  methods: { 
    async getCoverageData(id){
       const response = await getcoveragedata({r: id});
        if(response.data !== undefined && response.data.activeCoverage !== undefined && response.data.activeCoverage.coi !== undefined) {
           let coverage = response.data.activeCoverage;
           this.insured_firstName = coverage.insured_firstName;
           this.insured_middleName = coverage.insured_middleName;
           this.insured_lastName = coverage.insured_lastName;
           this.insured_birthday = coverage.insured_birthday;
           this.beneficiary_firstName = coverage.beneficiary_firstName;
           this.beneficiary_middleName = coverage.beneficiary_middleName;
           this.beneficiary_lastName = coverage.beneficiary_lastName;
           this.beneficiary_birthday = coverage.beneficiary_birthday;
           this.beneficiary_contact = coverage.beneficiary_contact;
           this.beneficiary_relationship = coverage.beneficiary_relationship; 
           this.coi = coverage.coi; 
           this.sealproPlan = coverage.sealproPlan;
        }
    },
    async submitRenewal(){
       this.issubmitting = true;
       let data = {
          r: this.renewCoverage.recid,
          insured_firstName: this.insured_firstName,
          insured_middleName: this.insured_middleName,
          insured_lastName: this.insured_lastName,
          insured_birthday: this.insured_birthday,
          beneficiary_firstName:  this.beneficiary_firstName,
          beneficiary_middleName:  this.beneficiary_middleName,
          beneficiary_lastName:  this.beneficiary_lastName,
          beneficiary_birthday:  this.beneficiary_birthday,
          beneficiary_contact:  this.beneficiary_contact,
          beneficiary_relationship:  this.beneficiary_relationship,
          coi: this.coi,
       };
       const response = await renewcoverage(data);
      if(response.data !== undefined) {
          if(response.data.success) {
                  Swal.fire({
                  text: response.data.message,
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "Ok",
                  customClass: {
                    confirmButton: "btn fw-bold btn-light-primary"
                  }
                }).then(() => {
                  window.location.reload();
                });
                return;
          }
            Swal.fire({
              title: "",
              text: response.data.message ,
              icon: "error",
              confirmButtonClass: "btn btn-secondary"
            }); 
      }

    }
  },
  mounted() {  

    console.log(this.renewCoverage);
    if(this.renewCoverage !== undefined && this.renewCoverage.recid !== undefined) {
      this.getCoverageData(this.renewCoverage.recid);
    }
  }
});
