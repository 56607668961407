import request from '@/utils/request'

 

export function getcoveragedata(data) {
  return request({ url: 'vcoverage_getrenewaldata', method: 'post', data: data})
} 


export function renewcoverage(data) {
  return request({ url: 'vcoverage_renewcoverage', method: 'post', data: data})
} 